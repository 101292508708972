/* Sidebar */
.sidebar {
  min-height: calc(100vh - 60px);
  background: #ffffff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding: 0;
  width: 257px;
  z-index: 11;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  border-right: 1px solid #e3e3e3;
}


.sidebar .nav {
  overflow: hidden;
  flex-wrap: nowrap;
  flex-direction: column;
  margin-bottom: 60px;
}

.sidebar .nav .nav-item {
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-property: background;
  -webkit-transition-property: background;
}

.sidebar .nav .nav-item .collapse {
  z-index: 999;
}

.sidebar .nav .nav-item .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  white-space: nowrap;
  padding: 8px 15px;
  color: #000;
  -webkit-transition-duration: 0.45s;
  -moz-transition-duration: 0.45s;
  -o-transition-duration: 0.45s;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
}

.sidebar .nav .nav-item .nav-link i {
  color: inherit;
}

.sidebar .nav .nav-item .nav-link i.menu-icon {
  font-size: 1.125rem;
  line-height: 1;
  margin-right: 1.125rem;
  color: inherit;
}

.rtl .sidebar .nav .nav-item .nav-link i.menu-icon {
  margin-left: 2rem;
  margin-right: 0;
}

.sidebar .nav .nav-item .nav-link i.menu-icon:before {
  vertical-align: middle;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: auto;
  margin-right: 0;
  color: #000;
}

.rtl .sidebar .nav .nav-item .nav-link i.menu-arrow {
  margin-left: 0;
  margin-right: auto;
}

.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  content: "\F140";
  font-family: "Material Design Icons";
  font-style: normal;
  display: block;
  font-size: 1rem;
  line-height: 10px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.sidebar .nav .nav-item .nav-link .menu-title {
  color: inherit;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1;
  vertical-align: middle;
  margin-left: 20px;
}

.sidebar .nav .nav-item .nav-link .badge {
  margin-left: auto;
}

.rtl .sidebar .nav .nav-item .nav-link .badge {
  margin-left: 0;
  margin-right: auto;
}

.sidebar .nav .nav-item .nav-link[aria-expanded="true"] i.menu-arrow:before {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sidebar .nav .nav-item.active>.nav-link {
  background: initial;
  position: relative;
}

.sidebar .nav .nav-item.active>.nav-link i,
.sidebar .nav .nav-item.active>.nav-link .menu-title,
.sidebar .nav .nav-item.active>.nav-link .menu-arrow {
  color: #4d83ff;
}

.sidebar .nav:not(.sub-menu) {
  margin-top: .3rem;
}

.sidebar .nav:not(.sub-menu)>.nav-item {
  border-bottom: 1px solid #f3f3f3;
  margin-top: 0;
}

.sidebar .nav:not(.sub-menu)>.nav-item>.nav-link {
  margin: 0;
}

.sidebar .nav:not(.sub-menu)>.nav-item:hover>.nav-link {
  background: transparent;
  color: #4d83ff;
}

.sidebar .nav.sub-menu {
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  padding: 0.25rem 0 0.6rem 3.55rem;
}

.sidebar .nav.sub-menu .nav-item {
  padding: 0;
}

.sidebar .nav.sub-menu .nav-item::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  margin-top: 15px;
}

.sidebar .nav.sub-menu .nav-item .nav-link {
  color: #656565;
  padding: 0.6rem 1rem;
  position: relative;
  font-size: 0.875rem;
  line-height: 1;
  height: auto;
  border-top: 0;
}

.sidebar .nav.sub-menu .nav-item .nav-link:hover {
  color: #000000;
}

.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #000000;
  background: transparent;
}

.sidebar .nav.sub-menu .nav-item:hover {
  background: transparent;
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .m {
    position: fixed;
    max-height: calc(100vh - 60px);
    top: 60px;
    bottom: 0;
    overflow: visible;
    right: 140px;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  .sidebar-offcanvas.active {
    right: 0;
  }
}

/* Navbar */
.navbar {
  font-weight: 400;
  transition: background 0.25s ease;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
}

.navbar .navbar-brand-wrapper {
  background: #ffffff;
  border-bottom: 1px solid #e3e3e3;
  transition: width 0.25s ease, background 0.25s ease;
  -webkit-transition: width 0.25s ease, background 0.25s ease;
  -moz-transition: width 0.25s ease, background 0.25s ease;
  -ms-transition: width 0.25s ease, background 0.25s ease;
  width: 257px;
  height: 60px;
  border-right: 1px solid #e3e3e3;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
  margin-left: 1.375rem;
  margin-right: 1.375rem;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand {
  color: #27367f;
  font-size: 1.5rem;
  margin-right: 0;
  padding: .25rem 0;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand.brand-logo-mini {
  display: none;
}

@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand.brand-logo-mini {
    display: none;
    width: 1.875rem;
  }
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:active,
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:focus,
.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand:hover {
  color: #1b2658;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-brand img {
  width: calc(257px - 150px);
  max-width: 100%;
  height: 34px;
  margin: auto;
  vertical-align: middle;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-toggler {
  border: 0;
  color: #4a4a4a;
  font-size: 1.5rem;
  padding: 0;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-toggler:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .navbar-toggler:not(.navbar-toggler-right) {
    display: none;
  }
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini {
  padding-left: 0;
  text-align: center;
}

.navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini img {
  width: calc(70px - 30px);
  max-width: 100%;
  margin: auto;
}

.navbar .navbar-menu-wrapper {
  background: #ffffff;
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  color: #9b9b9b;
  padding-left: 1.062rem;
  padding-right: 1.062rem;
  width: calc(100% - 257px);
  height: 60px;
  border-bottom: 1px solid #e3e3e3;
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper {
    width: calc(100% - 171px);
    padding-left: 15px;
    padding-right: 15px;
  }
}

.navbar .navbar-menu-wrapper .navbar-toggler {
  border: 0;
  color: inherit;
  font-size: 1.5rem;
  padding: 0;
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler:not(.navbar-toggler-right) {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-toggler.navbar-toggler-right {
    padding-left: 15px;
    padding-right: 11px;
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item {
  margin-left: 1rem;
  margin-right: 1rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  color: inherit;
  font-size: 0.875rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search {
  margin-left: 0rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group {
  background: #ececec;
  border-radius: 4px;
  padding: 0 .75rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .dataTables_wrapper select,
.dataTables_wrapper .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group select,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .input-group-text {
  background: transparent;
  border: 0;
  color: #000;
  padding: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .input-group-text i {
  color: #9b9b9b;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .dataTables_wrapper select,
.dataTables_wrapper .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group select {
  margin-left: .5rem;
  height: 2.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control::-webkit-input-placeholder,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .dataTables_wrapper select::-webkit-input-placeholder,
.dataTables_wrapper .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group select::-webkit-input-placeholder {
  color: #9b9b9b;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control:-moz-placeholder,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .dataTables_wrapper select:-moz-placeholder,
.dataTables_wrapper .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group select:-moz-placeholder {
  color: #9b9b9b;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control::-moz-placeholder,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .dataTables_wrapper select::-moz-placeholder,
.dataTables_wrapper .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group select::-moz-placeholder {
  color: #9b9b9b;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .form-control:-ms-input-placeholder,
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group .dataTables_wrapper select:-ms-input-placeholder,
.dataTables_wrapper .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-search .input-group select:-ms-input-placeholder {
  color: #9b9b9b;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings {
  margin: 0;
  padding-left: 1.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings .nav-link {
  padding: 0;
  text-align: center;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-settings i {
  font-size: 1.5rem;
  vertical-align: middle;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile {
  margin-left: 1.8rem;
  margin-right: 1.8rem;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-name {
  margin-left: .5rem;
  margin-right: .5rem;
  color: #4a4a4a;
  font-weight: 500;
}

@media (max-width: 767px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link .nav-profile-name {
    display: none;
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.nav-profile .nav-link::after {
  color: #4a4a4a;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
  border: none;
  -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
  position: absolute;
  font-size: 0.9rem;
  margin-top: 0;
  right: 0;
  left: auto;
  top: 60px;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
  right: auto;
  left: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
  margin-bottom: 0;
  padding: .65rem 1.5rem;
  cursor: pointer;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .item-thumbnail img {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .item-thumbnail .item-icon {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  color: #ffffff;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .item-thumbnail .item-icon i {
  font-size: 17px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .item-content {
  padding-left: 0.937rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .badge {
  margin-left: 2.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item i {
  margin-left: 10px;
}

.rtl .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .badge {
  margin-left: 0;
  margin-right: 2.5rem;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-divider {
  margin: 0;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown i {
  margin-right: .5rem;
  vertical-align: middle;
}

@media (max-width: 991px) {
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown {
    position: static;
  }

  .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .navbar-dropdown {
    left: 20px;
    right: 20px;
    top: 60px;
    width: calc(100% - 40px);
  }
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator {
  position: relative;
  padding: 0;
  text-align: center;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator i {
  font-size: 1.5rem;
  margin-right: 0;
  vertical-align: middle;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator .count {
  position: absolute;
  left: 59%;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #ff4747;
  top: 9px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .count-indicator:after {
  display: none;
}

@media (min-width: 992px) {
  .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: auto;
  }

  .rtl .navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right {
    margin-left: 0;
    margin-right: auto;
  }
}

@media (max-width: 991px) {
  .navbar {
    flex-direction: row;
  }

  .navbar .navbar-brand-wrapper {
    width: 55px;
  }

  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo {
    display: inline-block;
  }

  .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
    display: inline-block;
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .navbar .navbar-brand-wrapper {
    width: 171px;
  }

  .navbar .navbar-brand-wrapper .brand-logo-mini {
    padding-top: 0px;
  }
}

/* Layouts */
@media (min-width: 992px) {
  .sidebar-icon-only .navbar .navbar-brand-wrapper {
    width: 70px;
  }

  .sidebar-icon-only .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper {
    margin-right: auto;
  }

  .sidebar-icon-only .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo {
    display: none;
  }

  .sidebar-icon-only .navbar .navbar-brand-wrapper .navbar-brand-inner-wrapper .brand-logo-mini {
    display: none;
  }

  .sidebar-icon-only .navbar .navbar-menu-wrapper {
    width: calc(100% - 70px);
  }

  .sidebar-icon-only .sidebar {
    width: 70px;
  }

  .sidebar-icon-only .sidebar .nav {
    overflow: visible;
  }

  .sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link {
    display: block;
    padding-left: .5rem;
    padding-right: .5rem;
    text-align: center;
    position: static;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge,
  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-sub-title {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 0 5px 5px 0px;
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link .menu-title {
    border-radius: 5px 0 0 5px;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-arrow {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
    border-radius: 0 5px 0 0px;
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item .nav-link[aria-expanded] .menu-title {
    border-radius: 5px 0 0 0;
  }

  .sidebar-icon-only .sidebar .nav .nav-item .collapse {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background: #ffffff;
    padding: 0.5rem 1.4rem;
    left: 70px;
    position: absolute;
    text-align: left;
    top: 0;
    bottom: 0;
    width: 190px;
    z-index: 1;
    line-height: 1.8;
    box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.25);
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title {
    left: auto;
    right: 70px;
    text-align: left;
    box-shadow: -4px 0px 7px 0px rgba(182, 185, 189, 0.25);
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link .menu-title:after {
    display: none;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .nav-link:hover .menu-title {
    background: #ffffff;
  }

  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    display: block;
    padding: .5rem 0;
    background: #ffffff;
    border-radius: 0 0 5px 0;
    position: absolute;
    left: 70px;
    width: 190px;
    box-shadow: 4px 4px 7px 0px rgba(182, 185, 189, 0.25);
  }

  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapse,
  .rtl.sidebar-icon-only .sidebar .nav .nav-item.hover-open .collapsing {
    left: auto;
    right: 70px;
    border-radius: 0 0 0 5px;
    box-shadow: -4px 4px 7px 0px rgba(182, 185, 189, 0.25);
  }

  .sidebar-icon-only .sidebar .nav.sub-menu {
    padding: 0 0 0 1.5rem;
  }

  .sidebar-icon-only .sidebar .nav.sub-menu .nav-item .nav-link {
    text-align: left;
    padding-left: 20px;
  }

  .sidebar-icon-only .main-panel {
    width: calc(100% - 70px);
  }
}


.page-body-wrapper {
  min-height: calc(100vh - 60px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;
  padding-top: 60px;
}

.page-body-wrapper.full-page-wrapper {
  width: 100%;
  min-height: 100vh;
  padding-top: 0;
}

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 257px);
  min-height: calc(100vh - 60px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 991px) {
  .main-panel {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: #f3f3f3;
  padding: 1.04rem 1.04rem;
  width: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .content-wrapper {
    padding: 1.5rem 1.5rem;
  }
}


.btn {
  font-size: 13px !important;
  text-transform: capitalize !important;
}

.thead {
  background-color: whitesmoke;
}

.public-DraftStyleDefault-ltr {
  height: 300px;
}

.start {
  color: red;
}

.is-active {
  color: #1e94e9 !important;
}

.pdf-view-icon {
  position: absolute;
  right: 34px;
  top: 32px;
}

.main-panel .form-group {
  margin-bottom: 10px;
}

.main-panel .form-group label {
  margin-bottom: 5px;
}

form.forms-sample div.text-end button {
  margin-right: 10px;
}

/* Cards */
.main-panel .card {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
}

.main-panel .card .card-body {
  padding: 1.5rem 1.875rem;
}

.main-panel .card .card-body+.card-body {
  padding-top: 1rem;
}

.main-panel .card .card-title {
  color: #4a4a4a;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
  font-size: .875rem;
  font-weight: bold;
}

.main-panel .card .card-subtitle {
  font-weight: 400;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.main-panel .card .card-description {
  margin-bottom: .875rem;
  font-weight: 400;
  color: #76838f;
}

.main-panel .card .card-title .btn {
  float: right;
}

.login-from {
  background: #fff !important;
  border: none !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -2px rgba(0, 0, 0, .05) !important;
  color: #121212 !important;
  margin: 2rem auto !important;
  max-width: 28rem !important;
  outline: none !important;
  padding: 2rem 2.5rem !important;
  width: 100% !important;
}


.login-text {
  color: black;
  font-size: 2rem;
  font-weight: 600;
}

.input-field {
  background: #f3f3f3;
  color: #121212;
  font-weight: 400;
  padding: 0.75rem 1.25rem;
  text-transform: inherit;
  width: 100%;
  border-radius: 2rem;
}

.login-btn {
  background: #1a73e8;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06);
  ;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  min-width: 40%;
  padding: 0.65rem 1.25rem;
  text-transform: capitalize;
  border-radius: 2rem;
}

.loader {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.img-box {
  width: 320px;
  padding: 10px;
  border: 5px solid gray;
  margin: 0;
}

.web-category {
  display: block;
}

.mobile-category {
  display: none;
}

@media only screen and (max-width:600px) {

  .web-category {
    display: none;
  }

  .mobile-category {
    display: block;
  }

  .sidebar {
    min-height: calc(100vh - 60px);
    background: #ffffff;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding: 0;
    width: 56px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    border-right: 1px solid #e3e3e3;
  }
  .form-select{
    margin-left: -14px;
    margin-top: 5px;
    margin-right: 30px;
  }
}